import './polyfills.js'
import SliderSlickInit from './sliderSlick.js'
import initAnchor from './anchor.js'
import initPopup from './popupInit.js'
import initSelect from './select.js'
import initTimer from "./timer";

document.addEventListener('turbolinks:load', function() {
    SliderSlickInit();
    initAnchor();
    initPopup();
    initSelect();
    initTimer();
});
