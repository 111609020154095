import 'slick-carousel'

function initSliderMain() {
  const $sliderTop = $('.slider-top');
  if (!$sliderTop.length) {
    return
  }
  $sliderTop.slick({
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    fade: true,
    dots: true,
  });

  document.addEventListener('turbolinks:before-cache', () => $sliderTop.slick('unslick'))
}


function initSliderThemes() {
    const $slidesThemes = $('.slides-themes');
    if (!$slidesThemes.length) {
        return;
    }

    $slidesThemes.slick({
        slidesToShow: 4,
        swipeToSlide: true,
        arrows: true,
        dots: false,
        infinite: true,
        speed: 500,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '30px',
                }
            },
        ]
    });
    document.addEventListener('turbolinks:before-cache', () => $slidesThemes.slick('unslick'))
}
function initSliderCards() {
    const $slidesCards = $('.cards-slides');
    if (!$slidesCards.length) {
        return;
    }

    $slidesCards.slick({
        slidesToShow: 4,
        swipeToSlide: true,
        arrows: true,
        dots: false,
        infinite: true,
        speed: 500,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '30px',
                }
            },
        ]
    });
    document.addEventListener('turbolinks:before-cache', () => $slidesCards.slick('unslick'))
}

function initSliders() {
  initSliderMain();
  initSliderThemes();
  initSliderCards();
}

export default initSliders
