function initAnchor() {
    document.addEventListener('turbolinks:click', function (event) {
        const anchorElement = event.target
        const isSamePageAnchor = (
            anchorElement.hash &&
            anchorElement.origin === window.location.origin &&
            anchorElement.pathname === window.location.pathname
        )
        if (isSamePageAnchor) {
            Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(
                event.data.url,
                Turbolinks.uuid()
            )
            event.preventDefault()
        }
    })
}

export default initAnchor;
