function initSelect() {
    const selectWrap = document.querySelector('.js-select-wrap');
    const selectWrap2 = document.querySelector('.js-select-wrap2');
    if (!selectWrap) return;
    const select = selectWrap.querySelector('select');
    if (!select) return;

    function onSelectChange(e) {
        const selectById = document.querySelector(`.js-template-select[data-theme="${e.target.value}"] select`);
        if (!selectById) return;
        const selectOld = selectWrap2.querySelector('select');
        selectOld.remove();
        const selectClone = selectById.cloneNode(true);
        selectWrap2.append(selectClone);
    }

    select.addEventListener('change', onSelectChange);
}

export default initSelect;
